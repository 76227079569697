import React, { useRef, useEffect, useState } from "react"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import SEO from "../components/seo"
import Loader from "../components/Loader"
import WeBelieve from "../components/WeBelieve"
import "../styles/pages-style/philosophy.scss"
import img from "../images/new-philosophy.svg"

const Philosophy = () => {
	const weBelieveBlock = useRef(null)
	const [offsetWeBelieve, setOffsetWeBelieve] = useState(0)
	const [bgChangeWeBelieve, setBgChangeWeBelieve] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetWeBelieve ? setBgChangeWeBelieve(true) : setBgChangeWeBelieve(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let weBelieveRect = weBelieveBlock.current.getBoundingClientRect()
		let weBelievesPos = weBelieveRect.top - bodyRect.top
		setOffsetWeBelieve(weBelievesPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetWeBelieve, handleScroll])

	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<div>
					<Heading
						caption="Philosophy"
						text="What we believe in"
                        img={img}
					/>
                    <div ref={weBelieveBlock} className={bgChangeWeBelieve ? 'change-bg-we-believe' : ''}>
                        <WeBelieve />
                    </div>
				</div>
			</Layout>
		</div>
	)
}

export default Philosophy
