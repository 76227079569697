import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const WeBelieve = () => {

	return (
		<div className="we-believe">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption we-believe__caption">We believe...</span>
                </Fade>
                <div className="we-believe__wrap">
                    <ol className="number-list we-believe__list">
                        <Fade bottom duration={1000}>
                            <li>In efficiency</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>That software can make life better</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>In continuous improvement</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>In our customers future</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>That software should be built in steps, so you see results along the way</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>That technology makes life better</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>That there's always a solution</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>In making it easy</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>In making our clients love their work</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>That technology is a facilitator</li>
                        </Fade>
                    </ol>
                    <div className="we-believe__bottom">
                        <Fade bottom duration={1000}>
                            <span className="title we-believe__title">And most of all</span>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p className="caption we-believe__text">In the power of listening!</p>
                        </Fade>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default WeBelieve
